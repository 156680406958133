import { PublicationState } from '~/model/api.ts'
import { fallbackLng, supportedLngs } from '~/utils/i18n.ts'

export function destructPath(pathname: string) {
  const pathnameParts = pathname.split('/')

  let locale
  let path
  let canonical

  if (supportedLngs.includes(pathnameParts[1] ?? '')) {
    locale = pathnameParts[1]
    path = pathnameParts.slice(2).join('/')
    canonical = pathnameParts[1] === 'en'
  } else {
    locale = fallbackLng
    path = pathnameParts.slice(1).join('/')
  }

  return { locale, path, canonical }
}

export async function getPageState(request: Request) {
  const url = new URL(request.url)
  const params = url.searchParams
  const { locale, path, canonical } = destructPath(url.pathname)

  const publicationState =
    !!process.env.PREVIEW_PAGE_SECRET &&
    !!params.get('preview') &&
    params.get('preview') === process.env.PREVIEW_PAGE_SECRET
      ? PublicationState.Preview
      : PublicationState.Live
  const variant = params.get('variant')

  return { publicationState, variant, locale, path, canonical }
}
