import * as React from 'react'
import {
  useParams,
  Link,
  NavLink,
  useLocation,
  type LinkProps,
  type NavLinkProps,
} from 'react-router'
import { supportedLngs } from '~/utils/i18n.ts'
import { destructPath } from '~/utils/page-state.ts'

export function useNormalizedLink(to: string) {
  const params = useParams()
  const { path: currentPath } = destructPath(useLocation().pathname)

  if (to.startsWith('#')) {
    return {
      link: to,
      local: true,
    }
  }

  const urlObject = new URL(to, ENV.BASE_URL)

  const linkType = getLinkType(to)
  if (linkType !== 'local') {
    return {
      link: to,
      local: false,
    }
  } else {
    let locale = null
    let { path } = destructPath(urlObject.pathname)

    if (urlObject.hash && path === currentPath) {
      return {
        link: urlObject.hash,
        local: true,
      }
    }

    if (params['*']) {
      locale = params['*'].split('/')[0]
    }

    if (params.locale) {
      locale = params.locale
    }

    path = path.startsWith('/') ? path : `/${path}`

    if (path === '/') {
      if (locale && supportedLngs.includes(locale)) {
        return { link: `/${locale}`, local: true }
      } else {
        return { link: '/', local: true }
      }
    } else {
      if (locale && supportedLngs.includes(locale)) {
        return {
          link: `/${locale}${path}${urlObject.searchParams.toString() ? `?${urlObject.searchParams.toString()}` : ''}${urlObject.hash}`,
          local: true,
        }
      } else {
        return {
          link: `${path}${urlObject.searchParams.toString() ? `?${urlObject.searchParams.toString()}` : ''}${urlObject.hash}`,
          local: true,
        }
      }
    }
  }
}

export function LocaleNavLink(props: NavLinkProps) {
  const { to, ...rest } = props
  const { link } = useNormalizedLink(to.toString())

  return <NavLink to={link} {...rest} />
}

export function getLinkType(link: string): 'internal' | 'external' | 'local' {
  const urlObject = new URL(link, ENV.BASE_URL)
  if (
    urlObject.hostname === 'rouvy.com' ||
    urlObject.hostname === 'rouvy.dev' ||
    urlObject.hostname === 'localhost'
  ) {
    if (urlObject.pathname.startsWith('/brand-manual')) {
      return 'external'
    }
    return 'local'
  }
  if (/(account|riders)\.rouvy\.(dev|com)$/.test(urlObject.hostname)) {
    return 'internal'
  }
  return 'external'
}

export const NormalizedLink = React.forwardRef<HTMLAnchorElement, LinkProps>(
  (props, ref) => {
    const { to, children, ...rest } = props
    const { link, local } = useNormalizedLink(to.toString())

    return link.startsWith('#') || !local ? (
      <a
        href={link}
        ref={ref}
        {...rest}
        data-external={local ? undefined : true}
      >
        {children}
      </a>
    ) : (
      <Link ref={ref} to={link} {...rest}>
        {children}
      </Link>
    )
  },
)

NormalizedLink.displayName = 'NormalizedLink'
